import { ref, reactive, toRefs  } from "vue";
import api from '@/helpers/api'
import { env } from '@/configs'


const issuesState = reactive({
  issues: null,
  loading: true,
  error: false
})

export default function useIssuesProgress() {

  function fetchIssues(params) {
    issuesState.loading = true

    api.get({
      url: env.GITLAB_API_URL + '/issues',
      data: params ? Object.assign({}, params) : ''
    }).then(response => {
        if(response.status === 200) {

          const _data = response.data
          issuesState.issues = _data
          issuesState.loading = false
        }
      })
      .catch(error => {
        console.log(error);
        issuesState.error = true;
      })
      .finally(() => (
        issuesState.loading = false
    ));
  }

  let _params = { state: 'opened', scope: 'all', with_labels_details: true, order_by: 'updated_at', labels: 'In Progress' }

  function refetchIssues() {
    issuesState.issues = fetchIssues(_params);
  }

  issuesState.issues = fetchIssues(_params);


  return {
    ...toRefs(issuesState),
    refetchIssues
  }
}
