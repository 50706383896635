<template>
  <div class="box is-shadowless">
    <Columns>
      <Column>
        <div class="is-flex is-align-items-center">
          <block-title customClass="my-0 mr-5" title="Tasks In Progress" />
          <button class="button is-success is-small is-rounded" @click.prevent="refetchIssues">Refresh</button>
        </div>
        <hr />
      </Column>
    </Columns>

    <Columns v-if="loading && !issues">
      <Column>
        <Loading  />
      </Column>
    </Columns>

     <!-- class="is-12 p-0 pb-1" class="is-half-mobile is-one-third-desktop" -->
     <!--  class="is-12 p-0 pb-0" -->
     <div v-if="!loading" customClass="mt-4 is-shadowless">
      <Columns v-if="issues" isMobile isMultiline customClass="mt-3 is-gapless">
        <Column  v-for="(item, index) in issues"
              :key="index"
              class="is-half-mobile is-one-quarter-desktop is-one-quarter-widescreen"
        >

          <component :is="currentView" :item="item" @titleClickHandle="toggleIssueDetail(item)"></component>

        </Column>

      </Columns>
    </div>


    <!-- append to body-->

    <teleport to="body">
        <sidebar>
          <issue-detail-comp v-if="selectedIssue" :item="selectedIssue" />
        </sidebar>
    </teleport>

  </div>



</template>

<script>
  import { defineAsyncComponent, toRefs,reactive, onUnmounted } from "vue";
  import useIssuesProgress from '@/use/useProgress'
  import useGeneral from '@/use/useGeneral'

  export default {
    name: 'TaskInProgress',
    components: {
      Columns: defineAsyncComponent(() => import('@/components/ds/Columns.vue')),
      Column: defineAsyncComponent(() => import('@/components/ds/Column.vue')),
      // Box: defineAsyncComponent(() => import('@/components/ds/Box')),
      BlockTitle: defineAsyncComponent(() => import('@/components/ds/BlockTitle')),
      Loading: defineAsyncComponent(() => import('@/components/general/Loading')),
      Sidebar: defineAsyncComponent(() => import('@/components/general/Sidebar')),
      // ProjectName: defineAsyncComponent(() => import('@/components/widgets/taskProgress/projectName')),
      IssueDetailComp: defineAsyncComponent(() => import('@/components/issues/IssueDetailPanel')),
      // IssueProgressRow: defineAsyncComponent(() => import('@/components/issues/progress/IssueProgressRow')),
      IssueProgressCard: defineAsyncComponent(() => import('@/components/issues/progress/IssueProgressCard'))
    },
    setup () {
      const { projects, issues, loading, error, refetchIssues } = useIssuesProgress()
      const { setSidebarHandle } = useGeneral();
      const state = reactive({
        selectedIssue: null,
        currentView: 'IssueProgressCard',
      })

      function toggleIssueDetail (payload) {
        state.selectedIssue = Object.assign({}, payload)
        setSidebarHandle(true)
      }

      onUnmounted(()=> {
        state.selectedIssue = null
      })

      return {
        projects,
        issues,
        error,
        loading,
        refetchIssues,
        toggleIssueDetail,
        ...toRefs(state)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .progress_card {
    position: relative;
    overflow: hidden;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 2%), 0 0px 0 1px rgb(10 10 10 / 1%);
    transition: all 880ms ease;

    &__title {
      /* min-height: 60px; */
      line-height: 1.3;
    }

    &::before {
      content: '';
      /* background: var(--bkgColor); */
      width: 100%;
      height: 8px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &:hover {
      box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
    }

  }
</style>
